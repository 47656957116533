var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{staticStyle:{"overflow-y":"scroll"},attrs:{"zIndex":1000,"title":_vm.dialog.type === 'add'? 'Assign to a Unit' : 'Edit Unit',"width":720,"visible":_vm.dialog.show,"wrapStyle":{ overflow: 'auto'},"maskClosable":false},on:{"close":_vm.onClose}},[_c('bh-loading',{attrs:{"show":_vm.loading}}),_c('a-form-model',{staticClass:"h-full dF fC jSB"},[_c('div',{staticClass:"f1"},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-model-item',{attrs:{"label":"Unit No.","required":""}},[_c('a-select',{attrs:{"placeholder":"Tags Mode"},model:{value:(_vm.lot.unit),callback:function ($$v) {_vm.$set(_vm.lot, "unit", $$v)},expression:"lot.unit"}},_vm._l((_vm.units),function(unit){return _c('a-select-option',{key:unit.id},[_vm._v("Unit "+_vm._s(unit.unitNumber)+_vm._s(unit.name ? ` - ${unit.name}` : ''))])}),1)],1)],1)],1),_c('a-form-model-item',{attrs:{"label":"Featured Image","required":""}},[_c('div',{staticStyle:{"width":"50%"}},[_c('ImageBox',{attrs:{"type":2,"img":_vm.lot.image},on:{"callback":_vm.selectImage}})],1)]),_c('a-form-model-item',{attrs:{"label":"Media"}},[_c('div',{staticStyle:{"width":"50%"}},[_c('ImageBox',{attrs:{"promptText":'Add Media',"mediaType":'all',"uploadText":'UPLOAD MEDIA',"removeText":'REMOVE MEDIA',"type":2,"img":_vm.lot.video},on:{"callback":_vm.selectVideo}})],1)])],1),_c('div',{style:({
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        })},[_c('div',{staticClass:"dF jSB"},[_c('div',[(_vm.dialog.type === 'edit')?_c('a-button',{attrs:{"size":"large","type":"danger","ghost":"","icon":"delete"},on:{"click":_vm.deleteLot}},[_vm._v("DELETE")]):_vm._e()],1),_c('div',[_c('a-button',{staticClass:"mr-3 cancel-button",attrs:{"size":"large"},on:{"click":_vm.onClose}},[_vm._v(" CANCEL ")]),_c('a-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.submitButton}},[_vm._v(_vm._s(_vm.dialog.type === 'add'? 'CREATE' : 'UPDATE'))])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }